<template>
  <div>
    <div class="flex items-center justify-center text-center sm:block sm:p-0">
      <div
        class="
          flex
          justify-center
          items-center
          text-left
          overflow-hidden
          transform
          transition-all
          scrollbar-hidden
        "
        @click="() => (isError ? onCloseModal(true) : {})"
      >
        <div
          class="modal-container flex flex-col relative"
          @click.stop="() => {}"
        >
          <div class="flex w-full sm:hidden justify-center">
            <button
              v-if="isError"
              class="
                text-2xl
                mt-3
                font-bold
                rounded-full
                text-white
                bg-black
                h-9
                w-9
                justify-center
                items-center
                text-center
                z-40
              "
              @click.stop="onCloseModal"
            >
              X
            </button>
          </div>
          <a
            v-if="!isChecking && !isError && !isSuccess"
            @click="
              () => {
                step === 3 ? onSent() : (step = step + 1);
              }
            "
            class="
              cursor-pointer
              absolute
              text-black
              font-bold
              right-5
              leading-3
              top-500
              sm:top-64
              flex flex-col
              items-center
              justify-center
              z-50
              w-32
            "
            style="font-size: 10px"
          >
            <img src="@/assets/icon_right.png" class="mb-3" />
            <div class="text-center" v-if="step === 1 || step === 2">
              GO TO THE <br />
              NEXT STEP
            </div>
            <div class="text-center w-32" v-if="step === 3">
              ONCE WE RECEIVE YOUR PAYMENT, YOUR ORDER WILL BE EMAILED/TEXTED TO
              YOU.
            </div>
          </a>
          <div
            class="flex w-full flex-col px-16 py-2"
            :class="!isChecking && !isError && !isSuccess ? 'border-b' : ''"
            style="min-height: 80px"
          >
            <div
              class="
                flex flex-col
                sm:flex-row
                relative
                justify-center
                items-center
                mt-3
              "
              v-if="!isChecking && !isError && !isSuccess"
            >
              <div class="loader w-8" />
              <div>
                <div class="text-red-700 font-bold text-sm text-center px-3">
                  Please follow the next 3 easy steps to complete your order
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col justify-center items-center mt-2"
            v-if="!isChecking && !isError && !isSuccess"
          >
            <div
              class="
                flex
                items-center
                justify-center
                text-xs text-black
                font-semibold
              "
            >
              STEP
              <div
                class="
                  w-9
                  h-9
                  mx-1.5
                  rounded-full
                  bg-gray-300
                  font-semibold
                  flex
                  justify-center
                  items-center
                  text-2xl
                "
              >
                {{ step }}
              </div>
              OF 3
            </div>
            <div
              class="text-center font-bold text-xl leading-6 mt-2"
              style="color: #26d651"
              v-if="step === 1"
            >
              GO TO CASHAPP AND ENTER <br />${{ numeral(price).format("0,0") }}
              AS THE AMOUNT TO SEND
            </div>
            <div
              class="text-center font-bold text-xl leading-6 mt-2"
              style="color: #26d651"
              v-if="step === 2"
            >
              ENTER $ADAMARIEMPIRE<br />IN THE 'TO' BOX
            </div>
            <div
              class="text-center font-bold text-xl leading-6 mt-2"
              style="color: #26d651"
              v-if="step === 3"
            >
              ENTER {{ state.user.payCode }} IN THE<br />'FOR' BOX AND CLICK PAY
            </div>
          </div>
          <div
            class="
              flex flex-col
              sm:flex-row
              justify-between
              bg-white
              relative
              w-full
              custom-height
            "
            style="border-bottom-right-radius: 60px"
          >
            <div
              v-if="!isChecking && !isError && !isSuccess"
              class="
                flex
                justify-center
                sm:justify-start
                items-end
                mt-3
                mb-24
                pl-3
                w-full
                sm:w-1/3
              "
            >
              <div class="bg-gray-300 px-2 py-3 text-center w-32">
                <div class="text-xs font-bold text-black">Time Left</div>
                <div class="text-4xl font-semibold text-black leading-8">
                  {{ time }}
                </div>
                <a
                  class="
                    text-gray-500
                    font-medium
                    underline
                    text-xs
                    cursor-pointer
                  "
                  @click="onCancel"
                  >Cancel Order</a
                >
              </div>
            </div>
            <div
              v-if="!isChecking && !isError && !isSuccess"
              class="
                w-full
                sm:w-1/3
                flex
                items-center
                justify-center
                relative
                mt-36
                sm:mt-0
              "
            >
              <img src="@/assets/icon_background.png" class="absolute" />
              <img src="@/assets/icon_phone.png" class="absolute" />
              <div
                class="absolute font-bold bg-white text-center"
                style="
                  font-size: 40px;
                  color: #26d651;
                  margin-top: -100px;
                  width: 197px;
                "
              >
                ${{ numeral(price).format("0,0") }}
              </div>
              <div
                style="color: #26d651; top: 95px; marginLeft: -53px"
                class="font-semibold absolute z-20 bg-white w-24"
              >
                {{ state.user.payCode }}
              </div>
            </div>

            <div
              v-if="!isChecking && !isError && !isSuccess"
              class="flex flex-col items-center w-full sm:w-1/3 mt-64 sm:mt-0"
            >
              <div
                class="
                  flex
                  mb-10
                  sm:mb-0
                  sm:absolute
                  bottom-0
                  sm:bottom-24
                  z-10
                "
              >
                <div class="flex flex-col items-center justify-center">
                  <div class="mb-2 font-black text-sm" style="color: #646464">
                    PAYING WITH
                  </div>
                  <img
                    src="@/assets/icon_cash.png"
                    class="w-48 h-13 object-contain"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isChecking && !isError && !isSuccess"
              class="flex flex-col justify-center items-center w-full mb-36"
            >
              <div class="loader" />
              <div class="text-sm font-bold text-red-600 mt-3 text-center">
                We are checking for your payment. Please wait...
                <br />This process may take several minutes to process. Thanks
                for your patience. <br />If you need assistance, call (310)
                424-1276
              </div>
              <div
                class="
                  mt-10
                  rounded-md
                  border border-gray-400
                  px-4
                  py-4
                  text-center
                "
              >
                <div class="font-bold text-sm text-gray-400">
                  Are you sure you entered<br />
                  your special code in the memo?
                </div>
                <div class="text-2xl font-bold text-gray-400">
                  {{ state.user.payCode }}
                </div>
              </div>
            </div>
            <div
              v-if="isError"
              class="flex flex-col justify-center items-center w-full mb-36"
            >
              <img src="@/assets/error_alert.png" />
              <div class="text-sm font-bold text-red-600 mt-3 text-center">
                We could not detect your payment.<br />
                Please call (310) 424-1276 for assistance.
              </div>
              <div
                class="
                  mt-10
                  rounded-md
                  border border-gray-400
                  px-4
                  py-4
                  text-center
                "
              >
                <div class="font-bold text-sm text-gray-400">
                  Are you sure you entered<br />
                  your special code in the memo?
                </div>
                <div class="text-2xl font-bold text-gray-400">
                  {{ state.user.payCode }}
                </div>
              </div>
              <div class="flex justify-between items-center px-20 mt-16 w-full">
                <div
                  class="
                    rounded-lg
                    text-xs text-gray-500
                    px-3
                    py-1
                    bg-gray-200
                    font-semibold
                  "
                >
                  Possible issues:
                </div>
                <a
                  class="
                    text-indigo-700
                    underline
                    text-xs
                    cursor-pointer
                    font-semibold
                  "
                  >I forgot to enter my special code</a
                >
                <a
                  class="
                    text-indigo-700
                    underline
                    text-xs
                    cursor-pointer
                    font-semibold
                  "
                  >CashApp won't allow me to sent to an payments@adamari.net</a
                >
              </div>
            </div>
            <div
              v-if="isSuccess"
              class="flex flex-col justify-center items-center w-full mb-36"
            >
              <img src="@/assets/check_double.png" />
              <div class="text-sm font-bold text-green-600 mt-3 text-center">
                Success! We found your payment.<br />Please check your email for
                your tickets.
              </div>
            </div>
            <img
              src="@/assets/logo_bottom.png"
              class="hidden sm:grid absolute bottom-0 w-full -left-0"
            />
            <img
              src="@/assets/logo_bottom.png"
              class="grid sm:hidden h-16 object-cover left-0"
            />
          </div>
        </div>
      </div>
    </div>
    <AddMinute
      v-if="isShow"
      :is-open="isShow"
      :on-close-modal="() => (isShow = false)"
      :on-add="onAdd"
    />
  </div>
</template>

<script>
import { json } from "overmind";
import AddMinute from "@/components/common/AddMinute";
import numeral from "numeral";

export default {
  components: { AddMinute },
  data() {
    return {
      total: json(this.state.user.checkout?.total),
      future: new Date(
        new Date().setMinutes(new Date().getMinutes() + 15)
      ).getTime(),
      time: null,
      isShow: false,
      timer: null,
      step: 1,
      numeral,
    };
  },
  props: [
    "onClose",
    "onSent",
    "onCancel",
    "isChecking",
    "isSuccess",
    "isError",
    "price",
  ],
  methods: {
    onCloseModal(isCancel) {
      this.step = 1;
      if (isCancel === true) {
        this.onCancel();
      } else {
        this.onClose();
      }
      this.isShow = false;
    },
    onAdd() {
      console.log("-=-=-=-=");
      this.future = new Date(
        new Date(this.future).setMinutes(new Date(this.future).getMinutes() + 5)
      ).getTime();
      this.isShow = false;
    },
  },
  watch: {
    isChecking: function (val) {
      if (val) {
        clearInterval(this.timer);
      }
    },
  },
  created() {
    this.timer = setInterval(async () => {
      const now = new Date().getTime();
      const distance = this.future - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 2 * 60 * 1000) {
        this.isShow = true;
      }
      if (distance < 0) {
        clearInterval(this.timer);
        await this.actions.cart.saveCart({
          userId: this.state.currentUser?.id,
          where: { id: this.state.currentCart?.id },
          data: { isPending: false },
        });
        await this.actions.cart.setEmptyCart();
        this.actions.alert.showSuccess({
          message: "Cancelled order successfully!",
        });
        this.$router.go(-1);
      }
      this.time = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    }, 1000);
  },
};
</script>

<style lang="css" scoped>
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2255bf;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.circle_text {
  width: 4vw;
  height: 4vw;
  font-size: 3vw;
}

label,
button,
h2,
h3,
h4,
div,
span {
  font-family: "Montserrat";
}

@media (max-width: 746px) {
  .modal-container {
    width: 100vw !important;
    border-radius: 0px !important;
    margin: 0px !important;
    height: 100%;
    overflow-y: scroll;
  }

  .custom-height {
    height: auto !important;
  }
}

.custom-height {
  height: 500px;
}

.modal-container {
  width: 970px;
  margin: 10px;
  /*padding: 20px 30px;*/
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 67px;
  border-bottom-left-radius: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
