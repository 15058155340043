<template>
  <div>
    <Text content="Payment" size="xl" weight="medium" color="gray-900" />
    <div
      class="prose prose-sm text-gray-500 max-w-7xl mt-1"
      v-html="
        `Congrats on signing your contract! Now it's time to make your deposit to fully secure your date. Your deposit will be non-refundable, but your date will be secured. You will be emailed a copy of your contract and receipt once you place your deposit. You have 72 hours to pay deposit before the hold is released.`
      "
    />
    <div class="border mt-2"></div>
    <div class="mt-5 space-y-3">
      <Text content="Deposit Due Now: $1500" weight="semibold" size="lg" />
      <Tab :tabs="tabs" @onClickItems="(items) => (tabs = items)" />
      <div v-if="tabs.findIndex((t) => t.current) === 0">
        <div v-if="payments?.length > 0">
          <div class="py-5">
            <RadioGroup
              v-bind="paymentMethods"
              v-model:value="selectedPayment"
            />
            <div
              v-if="selectedPayment?.title?.includes('Add')"
              class="space-y-3 mt-5"
            >
              <Input
                v-model:value="paymentForm.cardNumber"
                label="Credit Card Number"
                placeholder="XXXX XXXX XXXX XXXX"
                input-mask="#### #### #### ####"
              />
              <Input
                v-model:value="paymentForm.expirationDate"
                label="Expiration Date"
                placeholder="MM/YYYY"
                input-mask="##/####"
              />
              <Input
                v-model:value="paymentForm.cvv"
                label="CVV"
                placeholder="XXX"
                input-mask="###"
              />
              <Input
                v-model:value="paymentForm.zipCode"
                label="Zip Code"
                placeholder="XXXXX"
                input-mask="#####"
              />
              <Checkbox label="Text this number about this reservation" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-y-3 mt-5">
            <Input
              v-model:value="paymentForm.cardNumber"
              label="Credit Card Number"
              placeholder="XXXX XXXX XXXX XXXX"
              input-mask="#### #### #### ####"
            />
            <Input
              v-model:value="paymentForm.expirationDate"
              label="Expiration Date"
              placeholder="MM/YYYY"
              input-mask="##/####"
            />
            <Input
              v-model:value="paymentForm.cvv"
              label="CVV"
              placeholder="XXX"
              input-mask="###"
            />
            <Input
              v-model:value="paymentForm.zipCode"
              label="Zip Code"
              placeholder="XXXXX"
              input-mask="#####"
            />
            <Checkbox label="Text this number about this reservation" />
          </div>
        </div>
        <div class="pt-2 space-y-1 flex flex-col justify-center w-full">
          <Button
            content="FINALIZE RESERVATION"
            :variant="null"
            customClass="bg-black"
            @click="() => $emit('onFinalize', selectedPayment)"
          />
        </div>
      </div>
      <div v-else-if="tabs.findIndex((t) => t.current) === 1">
        <CashApp :price="price" />
      </div>
      <div v-else-if="tabs.findIndex((t) => t.current) === 2">
        <Zelle :price="price" />
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Link from "../../atoms/Link/Link.vue";
import Tab from "../../molecules/Tabs/Tabs.vue";
import Select from "../../molecules/Selects/Component/Component.vue";
import Input from "../../molecules/Inputs/Component/Component.vue";
import Checkbox from "../../molecules/Inputs/Checkbox/Checkbox.vue";
import RadioGroup from "../../molecules/RadioGroup/Simple/Simple.vue";
import Button from "../../atoms/Button/Button.vue";
import CashApp from "../../common/CashApp";
import Zelle from "../../common/Zelle";
import { get } from "lodash";

export default {
  components: {
    Icons,
    Text,
    Link,
    Tab,
    Select,
    Input,
    Checkbox,
    RadioGroup,
    Button,
    CashApp,
    Zelle,
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
    paymentForm: {
      type: Object,
      default: () => {},
    },
    payments: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [
        { name: "Credit Card", href: "#", current: true },
        { name: "Cash App", href: "#", current: false },
        { name: "Zelle", href: "#", current: false },
      ],
      selectedPayment: null,
    };
  },
  watch: {
    tabs: {
      async handler(val) {
        console.log(val, "va");
        const index = this.tabs.findIndex((t) => t.current);
        if (index === 1 || index === 2) {
          const { generatePayCode } = await this.actions.user.generatePayCode({
            name:
              this.state.currentUser?.firstName +
              " " +
              this.state.currentUser?.lastName,
            phone: this.state.currentUser?.phones?.[0]?.number,
            email: this.state.currentUser?.email,
            type: index === 1 ? "cashapp" : index === 1 ? "zelle" : "",
            amount: 1500,
            userId: this.state.currentUser?.id,
          });
          console.log(generatePayCode, "code");
        }
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
