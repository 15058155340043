
<template>
  <div>
    <Text
      v-if="label"
      size="base"
      weight="medium"
      color="gray-900"
      :content="label"
    />
    <Text
      v-if="description"
      size="sm"
      weight="normal"
      color="gray-500"
      :content="description"
      custom-class="leading-5"
    />
    <fieldset class="mt-4">
      <div class="space-y-4">
        <div
          v-for="item in items"
          :key="item.id"
          class="flex items-center"
        >
          <input
            :id="item.id"
            v-model="inputValue"
            name="item"
            type="radio"
            :value="item"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          >
          <Text
            :for="item.id"
            size="sm"
            weight="medium"
            color="gray-700"
            :content="item.title"
            class="ml-3 block"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
export default {
  components: {
    Text,
  },
  props: {
    /**
     * items to show as radiogroup items
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * for assigning predefined value
     */
    value: {
      type: Object,
      default: () => {},
    },
    /**
     * to display a heading
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * to display a description
     */
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
