
<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs"
        class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <a v-for="tab in tabs" :key="tab.name" @click="() => onClick(tab)"
          :class="[tab.current ? 'text-indigo-500 border-b-2 border-indigo-500' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm']"
          :aria-current="tab.current ? 'page' : undefined">
          {{ tab.name }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClick(tab) {
      const tabs = [...this.tabs];
      tabs.map(t => t.current = false)
      tabs.find(t => t.name === tab.name).current = true;
      this.$emit('onClickItem', tabs)
    }
  }
}
</script>
