<template>
  <div class="flex">
    <input v-model="inputValue" :id="id" :name="name" type="checkbox" class="w-4 h-4 mt-1 text-primary-600 border-gray-300 rounded focus:ring-primary-500">
    <slot v-if="leading" />
    <label v-else :for="id" class="block ml-2 text-gray-900">{{ label }}</label>
  </div>
</template>

<script>
/**
 * - Use an input to fill out the form with special label, hint, help text
 * - value param is required. and the other params are optional
 */
export default {
  components: {
    Text,
  },
  props: {
    /**
     * Use to identify the input form
     */
    id: {
      type: String,
      default: "",
    },
    /**
     * Use to identify the input form. same with id. but it could be a special word or something like that
     */
    name: {
      type: String,
      default: "",
    },
    leading: {
      type: Boolean,
      default: false
    },
    /**
     * Use to show what input form is
     */
    label: {
      type: String,
      default: "",
    },
		value: {
    	type: Boolean,
			default: false,
		}
  },
	computed: {
		inputValue: {
			// getter
			get() {
				return this.value;
			},
			// setter
			set(newValue) {
				// Note: we are using destructuring assignment syntax here.
				this.$emit("update:value", newValue);
			},
		},
	},
};
</script>
